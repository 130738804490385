<template>
    <div id="imnothing"></div>
</template>

<script>
export default {
    data() {
        return {
            count: 0,
            content: 'Info',
            autoHideDelay: 4000
        }
    },
    props: {
        alerts: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        popToast(opts) {
            var content = opts.content;
            // Use a shorter name for this.$createElement
            const h = this.$createElement;

            // Increment the toast count
            //this.count++ //DO IT OUTSIDE, SO U CAN RECICLE ALERTS FROM THE ARRAY
            // Create the message
            const vNodesMsg = h(
                'p',
                { class: ['text-center', 'mb-0'] },
                [
                    // h('b-spinner', { props: { type: 'grow', small: true } }),
                    Array.isArray(opts.content) ?
                    content.map(x => {
                        return h('li',{ class: ['text-left', 'list-unstyled'] },[this.$t(x)]);
                    })
                    : (opts.content || ' Proceso finalizado correctamente!')
                    //h('strong', {}, 'toast'),
                    //` message #${this.count} `,
                    // h('b-spinner', { props: { type: 'grow', small: true } })
                ]
            );
            // Create the title
            const vNodesTitle = h(
                'div',
                { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
                [
                    h('strong', { class: 'mr-2' }, opts.title || 'Info'),
                    // h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
                ]
            );
            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: opts.type || 'info',
                autoHideDelay: opts.autoHideDelay || this.autoHideDelay,
                noAutoHide: opts.noAutoHide,
                appendToast: false,
            });
        }
    },
    watch: {
        alerts: function (val) {
            if(val.length > 0) {
                let newAlert = val[0]; //BEFORE: val(this.count)
                this.count++;
                this.popToast(newAlert);
            }
        }
    }
}
</script>

<style>

</style>